import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import MAutocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from "react-hook-form";

import { InputCommonStyles } from '../Form.styles';
import Input from '../Input/Input';
import './Autocomplete.scss';

export default function Autocomplete({ form, schema }) {
  let options = schema.options || [];

  //Input schema
  let inputSchema = cloneDeep(schema);
  inputSchema.props = inputSchema.inputProps;

  //To fit child input in parent autocomplete width(responsive)
  inputSchema.props.style = inputSchema.props.style || {};
  inputSchema.props.style.width = '100%';

  //Removing unwanted properties from inputSchema
  delete inputSchema.inputProps;

  const autocompleteProps = schema.props || {};
  const style = { ...InputCommonStyles, ...autocompleteProps.style };

  return (
    <Controller
      control={form.control}
      name={schema.selector}
      rules={schema.rules}
      render={({ onChange, onBlur, value }) => (
        <MAutocomplete
          className="fh-autocomplete"
          options={options}
          getOptionLabel={(option) => option.label}
          onChange={(e, v) => onChange(v?.value)}
          value={options.find(option => option.value === value) || null}
          onBlur={onBlur}
          disabled={schema.disabled}
          style={style}
          {...autocompleteProps}
          renderInput={(params) =>
            <Input
              params={params}
              schema={inputSchema}
              form={{ errors: form.errors }} //Only sending errors to render by textfield helper text
            />
          }
        />
      )}
    />
  );
}