import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import MaterialSelect from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Controller } from 'react-hook-form';
import shortid from '../Utils/shortid';
import './Select.scss';

import { InputCommonStyles } from '../Form.styles';

export default function Select({ form, schema }) {
  const [isFocused, setFocus] = useState(false);

  const { errors, control } = form;
  const error = get(errors, schema.selector);

  //If helper text is set to visible on focus state
  const defaultHelperText = schema.showHelperTextOnFocus ?
    (isFocused ? schema.helperText : '') :
    schema.helperText;

  const helperText = error ? error.message : defaultHelperText;
  const selectProps = cloneDeep(schema.props) || {};
  const options = schema.options || [];
  const style = { ...InputCommonStyles, ...selectProps.style };

  const InputLabelProps = selectProps?.InputLabelProps || {};
  InputLabelProps.id = InputLabelProps.id || shortid();

  //This doesn't need to be part of FormControl
  delete selectProps.InputLabelProps;

  const onFocus = useCallback((e) => {
    setFocus(true);
    selectProps.onFocus && selectProps.onFocus(e);
    // onFocus doesn't affect the render method so no need to be part of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBlur = useCallback((e) => {
    setFocus(false);
    selectProps.onBlur && selectProps.onBlur(e);
    // onBlur doesn't affect the render method so no need to be part of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      className="fh-select"
      error={!!error}
      disabled={schema.disabled}
      {...selectProps}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
    >
      {selectProps?.label && <InputLabel {...InputLabelProps}>{selectProps.label}</InputLabel>}

      <Controller
        render={({ onChange, onBlur, value }) => (
          <MaterialSelect
            displayEmpty
            value={value || ''}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}>
            {schema.placeholder &&
              <MenuItem value={''} disabled>
                {schema.placeholder}
              </MenuItem>
            }

            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
            ))}
          </MaterialSelect>
        )}
        labelId={InputLabelProps.id}
        name={schema.selector}
        control={control}
        rules={schema.rules}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}