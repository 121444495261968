import React from 'react';
import classnames from 'classnames';
import './FormField.scss';

export default function FormField({ label,
  labelIcon: LabelIcon,
  required,
  className,
  hideLabel,
  spacing,
  isMobile,
  formFieldStyle,
  children
}) {

  let classes = classnames({
    [`fh-form-field-spacing-${spacing}`]: spacing,
    [className]: className,
    'fh-form-field-required': required,
    'fh-form-field-ismobile': isMobile
  });

  return (
    <div className={`fh-form-field ${classes}`} style={formFieldStyle}>
      {!hideLabel &&
        <div className="fh-form-field-left">
          {LabelIcon && <LabelIcon className="fh-form-field-left-icon" />}
          <div className="fh-form-field-left-label">{label}</div>
        </div>
      }
      <div className="fh-form-field-right">{children}</div>
    </div>
  );
}