import Input from './Input/Input';
import Checkbox from './Checkbox/Checkbox';
import Radio from './Radio/Radio';
import Select from './Select/Select';
import Text from './Text/Text';
import Composite from './Composite/Composite';
import Divider from './Divider/Divider';
import Autocomplete from './Autocomplete/Autocomplete';

export const Types = {
  Input: 'input',
  Select: 'select',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Text: 'text',
  Composite: 'composite',
  Divider: 'divider',
  Custom: 'custom',
  Autocomplete: 'autocomplete'
};

export const FormComponents = {
  [Types.Input]: Input,
  [Types.Select]: Select,
  [Types.Radio]: Radio,
  [Types.Checkbox]: Checkbox,
  [Types.Text]: Text,
  [Types.Composite]: Composite,
  [Types.Divider]: Divider,
  [Types.Autocomplete]: Autocomplete
};