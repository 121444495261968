import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper/FormFieldWrapper';
import './Composite.scss';

export default function Composite({ schema, ...rest }) {
  const props = schema.props || {};

  return (
    <div className={`fh-form-type-composite ${schema.inline && 'fh-form-type-composite-inline'}`} {...props}>
      {schema.children.map((child, index) =>
        <FormFieldWrapper key={index} schema={child} {...rest} />
      )}
    </div>
  );
}