import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import TextField from '@material-ui/core/TextField';
import { InputCommonStyles } from '../Form.styles';
import './Input.scss';

export default function Input({ form = {}, schema, params }) {
  const [isFocused, setFocus] = useState(false);

  const { register, errors } = form;
  const error = get(errors, schema.selector);

  //If helper text is set to visible on focus state
  const defaultHelperText = schema.showHelperTextOnFocus ?
    (isFocused ? schema.helperText : '') :
    schema.helperText;

  const helperText = error ? error.message : defaultHelperText;
  const inputProps = schema.props || {};
  const style = { ...InputCommonStyles, ...inputProps.style };

  const onFocus = useCallback((e) => {
    setFocus(true);
    inputProps.onFocus && inputProps.onFocus(e);
  }, [inputProps]);

  const onBlur = useCallback((e) => {
    setFocus(false);
    inputProps.onBlur && inputProps.onBlur(e);
  }, [inputProps]);

  return (
    <TextField
      className="fh-input"
      error={!!error}
      type={schema.subType}
      inputRef={register?.(schema.rules)}
      placeholder={schema.placeholder}
      name={schema.selector}
      disabled={schema.disabled}
      helperText={helperText}
      {...params}
      {...inputProps}
      onFocus={onFocus}
      onBlur={onBlur}
      style={style}
    />
  );
}