import React from 'react';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from 'react-hook-form';
import './Checkbox.scss';

export default function Checkbox({ form, schema }) {
  const { control, errors } = form;
  const error = get(errors, schema.selector);
  const helperText = error ? error.message : schema.helperText;
  const props = schema.props || {};

  return (
    <FormControl
      error={!!error}
      disabled={schema.disabled}
      className='fh-checkbox'
      {...props}
    >
      {props?.label && <FormLabel>{props.label}</FormLabel>}

      <FormGroup name={schema.selector} row={schema.inline}>

        <Controller
          render={props =>
            <FormControlLabel
              onChange={e => props.onChange(e.target.checked)}
              checked={props.value ?? false}
              control={<MaterialCheckbox color='primary' />}
            />
          }
          control={control}
          name={schema.selector}
          rules={schema.rules}
        />
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}