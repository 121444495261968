import React from 'react';
import get from 'lodash/get';

import { Types, FormComponents } from '../Form.consts';
import FormField from '../FormField/FormField';

export default function FormFieldWrapper({ schema = {}, data, ...rest }) {
  let FormComponent = FormComponents[schema.type];

  //If the schema is dependent on other schema values
  if (schema.dependsOn && schema.dependsOnValue !== get(data, schema.dependsOn)) {
    return null;
  }

  if (schema.type === Types.Custom) {
    FormComponent = schema.Component;
  }

  return (
    <FormField
      label={schema.label}
      labelIcon={schema.labelIcon}
      required={schema.rules?.required}
      hideLabel={schema.hideLabel}
      spacing={schema.spacing}
      formFieldStyle={schema.formFieldStyle}
      isMobile={schema.isMobile}>
      <FormComponent schema={schema} data={data} {...rest} />
    </FormField>
  );
}