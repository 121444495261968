import React from 'react';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import MaterialRadio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Controller } from 'react-hook-form';
import './Radio.scss';

export default function Radio({ form, schema }) {
  const { errors, control } = form;
  const error = get(errors, schema.selector);
  const helperText = error ? error.message : schema.helperText;
  const props = schema.props || {};

  return (
    <FormControl
      error={!!error}
      disabled={schema.disabled}
      className='fh-radio'
      {...props}
    >
      {props?.label && <FormLabel>{props.label}</FormLabel>}

      <Controller
        render={props => (
          <RadioGroup row={schema.inline} value={props.value} onChange={props.onChange}>
            {schema.options.map((item, index) => (
              <FormControlLabel
                key={index}
                control={<MaterialRadio color='primary' />}
                label={item.label}
                value={item.value}
              />
            ))}
          </RadioGroup>
        )}
        name={schema.selector}
        control={control}
        rules={schema.rules}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}