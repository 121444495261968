import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import merge from 'lodash/merge';

import FormFieldWrapper from './FormFieldWrapper/FormFieldWrapper';
import './Form.scss';

export default function Form({
  options,
  children,
  onInit,
  onSubmit,
  className = '',
  ...rest
}) {
  const form = useForm(options);
  const { handleSubmit, watch } = form;

  const formData = watch();
  const allData = merge({}, options?.defaultValues, formData);

  const renderFormFields = (schema = []) =>
    schema.map((schema, index) => (
      <FormFieldWrapper
        key={index}
        schema={schema}
        form={form}
        data={allData}
      />
    ));

  useEffect(() => {
    onInit && onInit({ form });
    // onInit and form doesn't affect the render method so no need to be part of deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form className={`fh-form ${className}`} onSubmit={handleSubmit(onSubmit)} noValidate {...rest}>
      {children({ renderFormFields })}
    </form>
  );
}