import { useEffect, useState } from "react";
import merge from 'lodash/merge';
import { Types } from "./Form.consts";

const MobileBreakPointWidth = 768;

function generateMobileSchema(schema) {
  const eachSchema = (schema) => {
    const props = {};
    props.props = {};
    props.props.label = schema.label;

    props.formFieldStyle = {
      ...schema.formFieldStyle,
      flex: 1
    };

    if (schema.type === Types.Input || schema.type === Types.Select) {
      props.props.variant = 'filled';
    }

    if (schema.type === Types.Autocomplete) {
      props.inputProps = props.inputProps || {};
      props.inputProps.variant = 'filled';
      props.inputProps.label = schema.label;
    }

    if (schema.type === Types.Composite) {
      props.children = schema.children.map(eachSchema)
    }

    props.isMobile = true;
    return merge({}, schema, props);
  };

  return schema.map(eachSchema);
}

export default function ResponsiveSchema({ schema, breakpoint, children }) {
  const [responsiveSchema, setSchema] = useState(schema);
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const breakpointWidth = breakpoint || MobileBreakPointWidth;
    const mobileSchema = generateMobileSchema(schema);

    function handleResize() {
      let clientWidth = window.innerWidth;
      if (clientWidth <= breakpointWidth) {
        setSchema(mobileSchema);
        setIsMobile(true);
      } else {
        setSchema(schema);
        setIsMobile(false);
      }
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [schema, breakpoint]);

  return children({ schema: responsiveSchema, isMobile });
}