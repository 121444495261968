import React from 'react';
import get from 'lodash/get';
import './Text.scss';

export default function Text({ data, schema }) {
  const value = get(data, schema.selector);
  const props = schema.props || {};

  return (
    <div className={'fh-form-text'} {...props}>{value || ''}</div>
  );
}